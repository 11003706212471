import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Helmet} from "react-helmet";
import beeLogo from '../assets/static/images/logo-gray-scale.png'
// import wallPaper from './../assets/static/images/OMtmkr.jpg'
import wallPaper from './../assets/static/images/leeds-wall-paper.jpg'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import credentials from '../environments/credentials'
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from 'react-promise-tracker';
import LinearProgress from "@material-ui/core/LinearProgress";
import Loader from 'react-loader-spinner';


import { withStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';

import { headSection, cardsData, howWeHelp } from './configs/HomeData';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(14, 0, 6),
    // marginTop: '40px',
    backgroundImage: `url(${wallPaper})`,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    backgroundBlendMode: 'darken',
    backgroundSize: 'cover'
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // boxShadow: '0 1px 3px 0 rgba(37,32,31,.3)',
    boxShadow: '0 6px 2px 0 rgba(0,0,0,0.08)'
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain',
    marginBottom: '15px',
    marginTop: '20px',
  },
  cardContent: {
    flexGrow: 1,
  },
   root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  footer: {
    backgroundColor: '#2f3030',
    padding: theme.spacing(6),
    color: theme.palette.background.paper,
  },
  preFooter: {
    backgroundColor: '#dfe0e0',
    padding: theme.spacing(4),
    color: theme.palette.background.paper,
  },
}));

const WhiteTextTypography = withStyles({
  root: {
    color: "#9fa0a0"
  }
})(Typography);




export default function Home() {
  const classes = useStyles();
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("success")
  const [notificationMessage, setNotificationMessage] = React.useState("Form submitted! Our team will contact you.")

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const vertical = 'top';
  const horizontal = 'center';

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleButtonClick = (page) => {

    handleNavigate(page)

  };

  useEffect(() => {
    window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
  },[]);

  let history = useHistory();

  function handleNavigate(page) {
    if (page === 'IT Support') {
      history.push({
        pathname: '/services',
        section: 'IT Support',
      });
    }
    if (page === 'Web Design') {
      history.push({
        pathname: '/services',
        section: 'Web Design',
      });
    }
    if (page === 'Digital Marketing') {
      history.push({
        pathname: '/services',
        section: 'Digital Marketing',
      });
    }
    if (page === 'services') {
      history.push({
        pathname: '/services'
      });
    }
    if (page === 'contact') {
      history.push({
        pathname: '/contact'
      });
    }
  }

  const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();
    return (
      promiseInProgress &&
      <div
        style={{
         width: "100%",
         height: "100",
         display: "flex",
         justifyContent: "center",
         alignItems: "center"
        }}
        >
        <Loader type="ThreeDots" color="#2BAD60" height="60" width="60" />
      </div>
    );
  }

  function validateContact(phone) {
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return re.test(phone);
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    if ((name === '') || (phone === '')) {
      setOpen(true)
      setSeverity('error')
      setNotificationMessage('All fields are required')
      return;
    }
    if (!validateContact(phone)){
      setOpen(true)
      setSeverity('error')
      setNotificationMessage('Enter a valid contact number')
      return;
    }


    let userSearchQuery = {q: name, c: phone, siteID: 'core'}
    const apiEndPoint = `${credentials.API_URL}/contact-form/`
    console.log(apiEndPoint)
    debugger;
    const fetchSendDetails = fetch(apiEndPoint, {
      method: 'POST',
      body: JSON.stringify(userSearchQuery),
      headers: {'Content-Type':'application/json'}
    })
    trackPromise(
    fetchSendDetails.then(response => {
      if (response.status === 200) {
        setName('')
        setPhone('')
        setOpen(true)
        setSeverity('success')
        setNotificationMessage('Form submitted! Our team will contact you.')
      }
      return response
    })
    )
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta name="description"
              content="Consulting and digital agency dedicated to delivering digital services" />
        <title>Bee Frameworks - IT Consultancy Agency</title>
      </Helmet>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{vertical, horizontal}}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity}>
            {notificationMessage}
          </Alert>
        </Snackbar>
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <div
              style={{backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '150px',
                backgroundImage: `url(${beeLogo})`,
                backgroundSize: 'contain',
                marginBottom: '15px'
              }}
            >

            </div>
            <div style={{textAlign: 'center'}}>
              <Typography
                component="h1"
                variant="h4"
                align="center"
                color="textPrimary"
                style={{fontWeight: 'bold', color: 'white'}}
                gutterBottom>
                <span
                  // style={{backgroundColor: 'rgb(245, 195, 0)', padding: '0.0em 0.2em 0.0em 0.2em'}}
                >
                  {headSection.topTitle}</span>
              </Typography>
            </div>
            <div style={{textAlign: 'center'}}>
              <Typography variant="h6" align="center" style={{color: 'white', fontWeight: 300}}>
                {headSection.topSubTitle}
            </Typography>
            </div>

            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item elevation={0} >
                  <Button variant="contained" color="secondary" onClick={() => handleButtonClick('services')}>
                    More Info
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined"
                          style={{color: 'white', border: '1px solid #fff'}}
                          onClick={() => handleButtonClick('contact')}
                  >
                    Get in Touch
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="textPrimary"
            style={{fontWeight: 'bold', marginBottom: '40px'}}
            gutterBottom>
            Our Expertise
          </Typography>
          <Grid container spacing={4}>
            {cardsData.map((card) => (
              <Grid item key={card.id} xs={12} sm={6} md={4}>
                <Card className={classes.card} elevation={0}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.remote_image}
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography
                        gutterBottom
                        variant="h6"
                        component="h2"
                        style={{fontWeight: 'bold', textAlign: 'center'}}>
                      {card.title}
                    </Typography>
                    <Divider variant="middle" style={{ marginBottom: '15px', marginTop: '15px'}} />
                    <Typography style={{ textAlign: 'center', color: '#686868'}}>
                      {card.desc}
                    </Typography>
                  </CardContent>
                  <CardActions style={{marginBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      style={{fontWeight: 800}}
                      onClick={() => handleButtonClick(card.title)}>
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Container className={classes.cardGrid} style={{paddingTop: '10px'}} maxWidth="md">
          <Typography
              component="h1"
              variant="h4"
              align="center"
              color="textPrimary"
              style={{fontWeight: 'bold', marginBottom: '40px'}}
              gutterBottom>
              How We Can Help?
            </Typography>
          <Typography style={{ textAlign: 'center'}}>
            {howWeHelp.howText}
          </Typography>
          <List style={{backgroundColor: 'whitesmoke', marginTop: '20px'}}>
            <Grid container spacing={3}>

              <Grid item xs={12} sm={6}>
                <ListItem>
                <ListItemIcon>
                  <DoneAllIcon />
                </ListItemIcon>
                  <div className='offer'>
                    <ListItemText
                      disableTypography
                      primary={<Typography className='offer' type="body2" style={{ fontWeight: 800, fontSize: '1.1rem', color: '#f5c300'}}>
                        Receive tailored advice for your exact needs</Typography>}
                    />
                  </div>
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ListItem>
                <ListItemIcon>
                  <DoneAllIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                    primary={<Typography className='offer' type="body2" style={{ fontWeight: 800,  fontSize: '1.1rem', color: 'black'}}>
                      Implementation options available</Typography>}
                />
                </ListItem>
              </Grid>

            </Grid>
          </List>
          <div style={{boxShadow: '0 6px 2px 0 rgba(0,0,0,0.08)', paddingBottom: '64px'}}>
            <WhiteTextTypography
                component="h1"
                variant="h5"
                align="center"
                style={{fontWeight: 'bold', marginTop: '64px', marginBottom: '20px'}}
            >
                Book a FREE consultation
            </WhiteTextTypography>
            <form onSubmit={handleSubmit} className={classes.root} noValidate autoComplete="off">
              <div style={{textAlign: 'center'}}>
                <TextField
                  id="outlined-name"
                  label="Name"
                  value={name}
                  onChange={handleNameChange}
                  style={{paddingTop: '15px'}}
                />
                <TextField
                  id="phone-number"
                  label="phone"
                  value={phone}
                  onChange={handlePhoneChange}
                  style={{paddingTop: '15px'}}
                />
                <Button style={{marginTop: '25px'}} type='submit' variant="contained" color="secondary">
                      Book a FREE consultation
                </Button>
              </div>
            </form>
            <LoadingIndicator/>
          </div>
        </Container>
      </main>
      {/* End footer */}

    </React.Fragment>
  );
}