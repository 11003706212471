import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import {Helmet} from "react-helmet";


const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    fontWeight: 'bold'
  },
  cardHeaderPro: {
    backgroundColor: '#f5c300',
    fontWeight: 'bold'
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const tiers = [
  {
    title: 'Basics',
    price: '29',
    description: [
      '- £89 Deposit',
      '- 3 Pages',
      '- Free Standard Domain',
      '- Free SSL Certificate',
      '- Free Hosting',
      '- Google Analytics Connection',
      '- Frequent Backups'
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '49',
    description: [
      '- £149 Deposit',
      '- 3-5 Pages',
      '- Search Engine Optimised',
      '- Up To 5 Stock Images',
      '- Free Standard Domain',
      '- Free SSL Certificate',
      '- Free Hosting',
      '- Maintenance & Security',
      '- Google Analytics Connection',
      '- Frequent Backups',
      '- Social Feeds',
      '- Chatbot/Livechat',
      '- Unlimited Minor Changes',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'contained',
  },
  {
    title: 'eCommerce',
    price: '69',
    description: [
      '- £199 Deposit',
      '- Up to 15 Pages',
      '- Advanced Search Engine Optimisation',
      '- Up To 50 Stock Images',
      '- Free Standard Domain',
      '- Free SSL Certificate',
      '- Free Hosting',
      '- Maintenance & Security',
      '- Google Analytics Connection',
      '- Frequent Backups',
      '- Facebook Chatbot integration',
      '- Unlimited Minor Changes',
      '- eCommerce Functionality',
      '- Image & Site Optimisation',
      '- Unlimited Products',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];


export default function Pricing() {
  const classes = useStyles();

  useEffect(() => {

    window.scrollTo(0, 0)

  }, [])

  const handleButtonClick = (page) => {

    handleNavigate(page)

  };

  let history = useHistory();

  function handleNavigate(page) {
    if (page === 'contact') {
      history.push({
        pathname: '/contact'
      });
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta name="description"
              content="Affordable web design services" />
        <title>Bee Frameworks - Pay monthly web design</title>
      </Helmet>
      <CssBaseline />
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
            component="h1"
            variant="h4"
            align="center"
            color="textPrimary"
            style={{fontWeight: 'bold', marginBottom: '40px', marginTop: '64px'}}
            gutterBottom>
          Pricing
        </Typography>
        <Typography align="center">
          Pay monthly options to suit your budget. Affordable and bespoke websites designed and developed to engage
          your target market, deliver top-notch performance, and organically build your Google rankings
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} >
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center', variant: 'h6'}}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={tier.title === 'Pro' ? classes.cardHeaderPro: classes.cardHeader }
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      £{tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      /mo
                    </Typography>
                  </div>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} color="secondary" onClick={()=> handleButtonClick('contact')}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}