import React, {useEffect} from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import wallPaper from "../assets/static/images/OMtmkr.jpg";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(14, 0, 6),
    backgroundImage: `url(${wallPaper})`,
    backgroundColor: 'rgba(0, 0, 0, 0.13)',
    backgroundBlendMode: 'darken',
    backgroundSize: 'cover'
  },
  sections: {
  },
  sectionTitles: {
    paddingBottom: '30px',
    fontWeight: 'bolder',
    paddingTop: '64px',
  },
  coreValues: {
    fontWeight: 'bold'
  }
}));


export default function About(props) {

  const classes = useStyles();

  useEffect(() => {

  }, [])

  return (
  <React.Fragment>
    <Helmet>
    <meta name="description"
          content="Website design and development agency based in Leeds, West Yorkshire" />
    <title>Bee Frameworks - About</title>
    </Helmet>
    <CssBaseline />
    <main>
    {/* Hero unit */}
    <div className={classes.heroContent}>
    <Breadcrumb>
      <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
      <BreadcrumbItem active>About</BreadcrumbItem>
    </Breadcrumb>
      <Container maxWidth="sm">
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="textPrimary"
          style={{fontWeight: 'bold'}}
          gutterBottom>
          <span style={{backgroundColor: 'rgb(245, 195, 0)', padding: '0.0em 0.2em 0.0em 0.2em'}}>About</span>
        </Typography>
      </Container>
    </div>
    </main>
    <div>
      <div className={classes.sections}>
        <Container maxWidth="md">
          <Typography
          component="h1"
          variant="h5"
          align="center"
          color="textPrimary"
          className={classes.sectionTitles}
          gutterBottom>
            Who we are
          </Typography>
          <Typography>
            We are Bee Frameworks, a digital agency based in Leeds, West Yorkshire and we help our clients unlock uncommon
            growth in the face of disruption.
            Unprecedented levels of change and disruption require new thinking and transformative approaches to growth.
            From purpose to product, brand to experience, customers to operations, we bring the insight,
            rigor and expertise needed to both uncover and realize transformative opportunities
          </Typography>
        </Container>
      </div>
      <div className={classes.sections}>
        <Container maxWidth="md">
          <Typography
          component="h1"
          variant="h5"
          align="center"
          color="textPrimary"
          className={classes.sectionTitles}
          gutterBottom>
            Our Values
          </Typography>
          <Typography>
            <span className={classes.coreValues}>Customer Focus:</span> We prioritise our customers' needs
          </Typography>
          <Typography style={{paddingTop: '25px'}}>
            <span className={classes.coreValues}>Integrity:</span> We love what we do, we deliver on our commitments
            and act with integrity in everything we do.
          </Typography>
        </Container>
      </div>
    </div>
  </React.Fragment>
  );
}