console.log(process.env.REACT_APP_API_ENDPOINT)

console.log("=====================================================")

let credentials = {
  API_URL: "http://localhost:8000/api",
};

if(process.env.REACT_APP_API_ENDPOINT==="prod"){
  credentials = {
  API_URL: "https://exukonline.com/api",
  }
}

export default credentials;
