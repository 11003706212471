export const headSection = {
  logo: 'here',
  topTitle: 'Web Design - Leeds',
  topSubTitle: 'We help you develop your business on the internet'
}

export const cardsData = [
  {'title': 'Web Design', 'price': 250,
    'remote_image': 'https://i.ibb.co/0C2bn02/web-design-3d-final.png',
    'desc': 'Affordable websites designed and developed to engage your target market...', 'id': 2},
  {'title': 'IT Support', 'price': 100,
  'remote_image': 'https://i.ibb.co/59fVpNM/ezgif-com-webp-to-jpg.jpg',
  'desc': 'We offer a range of options depending on your requirements...', 'id': 1},
  {'title': 'Digital Marketing', 'price': 95,
    'remote_image': 'https://i.ibb.co/hVF80DV/audience-panel.png',
    'desc': 'From Social Media campaigns to Powerful Search Engine Optimisation strategies...', 'id': 3},
  ];

export const howWeHelp = {
  howText: 'We are passionate about helping clients on how they can utilise their information technology (IT)' +
    ' to optimally achieve their business goals. Let our consultants soothe your IT headache.'
}